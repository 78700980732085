<template>
  <div>
    <div class="grid today mb-4">
      <div>금일 통계</div>
      <div v-for="f in todayFields" :key="f.key">{{f.name}}</div>
      <div v-for="f in todayFields" :key="`today${f.key}`" @click="getOrder(f.noOrder, 'today', '', f.key)">
        <span>{{typeof(stat.today[f.key]) === 'number' ? $utils.rnc(stat.today[f.key]) : stat.today[f.key]}}</span>
      </div>
    </div>
    <b-row>
      <b-col class="mb-6">
        <div class="grid type mb-4">
          <div>유형별 통계</div>
          <div v-for="f in typeFields" :key="f.key">{{f.name}}</div>
          <template v-for="row in stat.type">
            <div v-for="f in typeFields" :key="`${row.type}_${f.key}`" @click="getOrder(f.noOrder, 'type', row.type, f.key)">
              <span>{{typeof(row[f.key]) === 'number' ? $utils.rnc(row[f.key]) : row[f.key]}}</span>
            </div>
          </template>
        </div>
      </b-col>
      <b-col class="mb-6">
        <div class="grid type mb-4">
          <div>담당자별 통계</div>
          <div v-for="f in managerFields" :key="f.key">{{f.name}}</div>
          <template v-for="(value, name) in stat.manager">
            <div v-for="f in managerFields" :key="value.manager+f.key" @click="getOrder(f.noOrder, 'manager', stat.manager[name].manager, f.key)">
              <span>{{typeof(stat.manager[name][f.key]) === 'number' ? $utils.rnc(stat.manager[name][f.key]) : stat.manager[name][f.key]}}</span>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-input-group>
      <b-input-group-prepend>
        <b-button variant="primary" @click="filterShop">
          <i class="fa fa-filter"></i> 필터링
        </b-button>
      </b-input-group-prepend>
      <b-form-input type="text" v-model="partnerFilter.filter" placeholder="shop id, name을 넣어주세요" @keyup="filterShop" @keypress.enter="filterShop" v-focus></b-form-input>
    </b-input-group>
    <b-form-radio-group class="col-form-label" v-model="partnerFilter.shopType" @change="filterShopType" :options="[
              {text: '전체', value: 'ALL'},
              {text: '부티크', value: 'boutique'},
              {text: '편집샵', value: 'edit_shop'},
              {text: '병행', value: 'parallel'},
            ]">
    </b-form-radio-group>
    <div class="mb-4">
      <hot-table data-key="partner_stat" ref="hotTablePartner" :settings="hotSettingPartner"></hot-table>
    </div>
    <hr/>
    <div class="mb-2 clearfix">
      <b-btn class="mr-3 pull-right" variant="success" @click="down" :disabled="busy.xlsx">Xlsx<b-spinner class="ml-1" small v-if="busy.xlsx"></b-spinner></b-btn>
      <b-btn class="mr-3 pull-right" variant="light" @click="copyOid" :disabled="busy.xlsx">Copy oid<b-spinner class="ml-1" small v-if="busy.xlsx"></b-spinner></b-btn>
    </div>
    <div class="mb-4">
      <hot-table data-key="order_stat" ref="hotTableOrder" :settings="hotSettingOrder"></hot-table>
    </div>


    <b-modal v-model="modal.ps_log" size="md" :title="'PS_log'">
      <div data-type="ps" >
        <perfect-scrollbar ref="ps_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
          <div v-for="i in this.logModal" v-if="!i._del_dt">
            <div class="message">
              <div>
                <small class="text-muted">{{i._name}}</small>
                <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                  <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('ps', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
                </small>
              </div>
              <template v-if="i.type === 'file'">
                <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
                <div></div>
              </template>
              <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
              <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
            </div>
            <hr>
          </div>
        </perfect-scrollbar>
        <div v-if="!this.logModal || this.logModal.filter(e=>!e._del_dt).length === 0">
          <div class="mt-3 mb-3 text-center font-weight-bold">PS Log가 없습니다</div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="modal.op_log" size="md" :title="'운영 Log'">
      <div data-type="ps" >
        <perfect-scrollbar ref="ps_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
          <div v-for="i in this.logModal" v-if="!i._del_dt">
            <div class="message">
              <div>
                <small class="text-muted">{{i._name}}</small>
                <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                  <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('ps', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
                </small>
              </div>
              <template v-if="i.type === 'file'">
                <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
                <div></div>
              </template>
              <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
              <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
            </div>
            <hr>
          </div>
        </perfect-scrollbar>
        <div v-if="!this.logModal || this.logModal.filter(e=>!e._del_dt).length === 0">
          <div class="mt-3 mb-3 text-center font-weight-bold">PS Log가 없습니다</div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>


    <b-modal v-model="modal.mng_log" size="md" :title="'주문 관리 log'">
      <div data-type="ps" >
        <perfect-scrollbar ref="ps_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
          <div v-for="i in this.logModal" v-if="!i._del_dt">
            <div class="message">
              <div>
                <small class="text-muted">{{i._name}}</small>
                <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                  <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('ps', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
                </small>
              </div>
              <template v-if="i.type === 'file'">
                <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
                <div></div>
              </template>
              <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
              <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
            </div>
            <hr>
          </div>
        </perfect-scrollbar>
        <div v-if="!this.logModal || this.logModal.filter(e=>!e._del_dt).length === 0">
          <div class="mt-3 mb-3 text-center font-weight-bold">PS Log가 없습니다</div>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<style scoped>
@import '~handsontable/dist/handsontable.full.css';
/* use grid : https://studiomeal.com/archives/533 */
.grid {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  gap: 1px;
}
.grid>div {
  outline: 1px solid #aaa;
}
.grid>div.th {
  font-weight: bold;
}
.today {
  grid-template-columns: repeat(5, minmax(10px, auto));
  font-size: 15px;
}
.today>div:nth-child(1) {
  grid-column: 1 / 6;
  background: yellow;
  font-weight: bold;
}
.today>div:nth-child(n+2):nth-child(-n+7) {
  background: lightblue;
}
.today>div:nth-child(n+8) {
  text-decoration: underline;
  cursor: pointer;
}

.type {
  grid-template-columns: repeat(6, minmax(10px, auto));
  font-size: 15px;
}
.type>div:nth-child(1) {
  grid-column: 1 / 7;
  background: yellow;
  font-weight: bold;
}
.type>div:nth-child(n+2):nth-child(-n+8) {
  background: lightblue;
}
.type>div:nth-child(n+9):not(:nth-child(7n+2)){
  text-decoration: underline;
  cursor: pointer;
}
.handsontable td {
  vertical-align: middle;
  text-align: center;
}
.handsontable .pointer{
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import { HotTable } from '@handsontable/vue';
import xlsx from '@/views/modules/Xlsx.vue'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp';
import {commaRenderer} from '@/shared/ht_helper';

export default {
  name: 'DeliveryDelayStat',
  components: {xlsx, HotTable},
  props: ['tabIndex'],
  data() {
    return {
      todayFields: [
        {key: 'TOTAL', name: '총 문제 배송건수'},
        {key: 'DEL_DELAY', name: '예상배송일 초과'},
        {key: 'PREPARE', name: '소싱지연'},
        // {key: 'ABROAD', name: '통관지연'},
        {key: 'ABROAD_COMPLETE', name: '출고지연'},
        {key: 'DOMESTIC', name: '택배지연'},
      ],
      typeFields: [
        {key: 'type', name: '배송유형', noOrder: true},
        {key: 'TOTAL', name: '총 문제 배송건수'},
        {key: 'DEL_DELAY', name: '예상배송일 초과'},
        {key: 'PREPARE', name: '소싱지연'},
        // {key: 'ABROAD', name: '통관지연'},
        {key: 'ABROAD_COMPLETE', name: '출고지연'},
        {key: 'DOMESTIC', name: '택배지연'},
      ],
      managerFields: [
        {key: 'manager', name: '담당자명', noOrder: true},
        {key: 'TOTAL', name: '총 문제 배송건수'},
        {key: 'DEL_DELAY', name: '예상배송일 초과'},
        {key: 'PREPARE', name: '소싱지연'},
        // {key: 'ABROAD', name: '통관지연'},
        {key: 'ABROAD_COMPLETE', name: '출고지연'},
        {key: 'DOMESTIC', name: '택배지연'},
      ],
      partnerFields: [
        {data: 'shop_id', name: 'Shop ID', width: 70, className: 'truncate htCenter', readOnly: true},
        {data: 'boutique', name: '파트너사명', width: 160, className: 'truncate htCenter', readOnly: true},
        {data: 'shop_type', name: 'Shop 유형', width: 90, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const type = this.partnerFilter.filterPartner[cellProperties.row].shop_type;
            td.innerHTML = `<div class="truncate htCenter" style="color: #777">${{boutique: '부티크', edit_shop: '편집샵', parallel: '병행'}[type]}</div>`;
          }, readOnly: true},
        {data: 'delivery_type', name: 'Shop 배송유형', width: 110, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const delivery_type = this.partnerFilter.filterPartner[cellProperties.row].delivery_type;
            td.innerHTML = `<div class="truncate htCenter" style="color: #777">${{both: 'both', abroad: '해외', domestic: '국내',}[delivery_type]}</div>`;
          }, readOnly: true},
        {data: 'logistics', name: '직배송 여부', width: 90, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const logistics = this.partnerFilter.filterPartner[cellProperties.row].logistics;
            td.innerHTML = `<div class="truncate htCenter" style="color: #777">${{balaan: 'Balaan 물류', direct: '직배송'}[logistics]}</div>`;
          }, readOnly: true},
        {data: 'delivery_day', name: '평균 배송일', width: 90, className: 'truncate htCenter', readOnly: true},
        {data: 'TOTAL', name: '총 문제 배송건수', width: 130, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
        {data: 'DEL_DELAY', name: '예상배송일 초과', width: 130, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
        {data: 'PREPARE', name: '소싱지연', width: 90, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
        // {data: 'ABROAD', name: '통관지연', width: 90, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
        {data: 'ABROAD_COMPLETE', name: '출고지연', width: 90, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
        {data: 'DOMESTIC', name: '택배지연', width: 90, className: 'truncate htCenter pointer', renderer: commaRenderer, readOnly: true},
      ],
      orderFields: [
        {data: 'orderno', name: '주문번호', width: 140, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            let e = order ? `<div class="truncate htCenter"><a href="https://hub.balaan.io/#/deliveryBoard/${order.oid}" target="_blank">${order.orderno}</a></div>` : value;
            td.innerHTML = e;
          }, readOnly: true},
        {data: 'order_detailno', name: '상세주문번호', width: 140, className: 'truncate htCenter', readOnly: true},
        {data: 'shop_id', name: 'SHOP ID', width: 70, className: 'truncate htCenter', readOnly: true},
        {data: 'boutique', name: '파트너사명', width: 190, className: 'truncate htCenter', readOnly: true},
        {data: 'order_status', name: '주문상태', width: 100, className: 'truncate htCenter', readOnly: true},
        {data: 'order_receive_date', name: '주문일', width: 90, className: 'truncate htCenter', readOnly: true},
        {data: 'order_status_date', name: '상태일시', width: 90, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            td.innerHTML = order ? `<div class="truncate htCenter" style="color: #777">${order.order_status_date}</div>` : '';
          }, readOnly: true},
        {data: 'shop_type', name: 'SHOP 유형', width: 80, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            td.innerHTML = order ? `<div class="truncate htCenter" style="color: #777">${{'boutique': '부티크', 'edit_shop': '편집샵', 'parallel': '병행'}[order.shop_type]}</div>` : '';
          }, readOnly: true},
        {data: 'delivery_type', name: '배송 유형', width: 90, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            td.innerHTML = order ? `<div class="truncate htCenter" style="color: #777">${order.delivery_type}${order.logistics === 'direct' ? ' 직배송' : '배송'}</div>` : '';
          }, readOnly: true},
        {data: 'delay', name: '지연내용', width: 120, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            td.innerHTML = order ? `<div class="truncate htCenter" style="color: #777">${{DEL_DELAY: '예상배송일 초과', PREPARE: '소싱지연', ABROAD_COMPLETE: '출고지연', DOMESTIC: '택배지연'}[order.delay]}</div>` : '';
          }, readOnly: true},
        {data: 'delay_day', name: '지연일', width: 50, className: 'truncate htCenter', readOnly: true},
        {data: 'del_plan_date', name: '고객 도착안내일', width: 110, className: 'truncate htCenter', readOnly: true},
        {data: 'ps_memo', name: 'PS 메모', width: 150, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            td.innerHTML = order ? `<div class="truncate htCenter pointer" style="color: #777" title="${order.ps_memo || ''}">${order.ps_memo || ''}</div>` : '';
          }, readOnly: true},
        {data: 'logs', name: 'Logs', width: 150, readOnly: true, renderer: (instance, td, row, col, prop, value, cellProperties) => {
            const order = this.filterOrders[cellProperties.row];
            const ps_log = order && order.ps_log ? order.ps_log.filter(e=>!e._del_dt) : [];
            const op_log = order && order.op_log ? order.op_log.filter(e=>!e._del_dt) : [];
            const mng_log = order && order.mng_log ? order.mng_log.filter(e=>!e._del_dt) : [];

            if (td) {
              td.className = 'htCenter htMiddle';
              td.innerHTML = `${ps_log.length ? `<b-button class="ps_log badge badge-warning" title="PS Log\n${ps_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${ps_log.length}</b-button>`: ''}`
                  + `${op_log.length ? `&nbsp;<b-button class="op_log badge badge-info" title="운영 Log\n${op_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${op_log.length}</b-button>`: ''}`
                  + `${mng_log.length ? `&nbsp;<b-button class="mng_log badge badge-success" title="주문관리 Log\n${mng_log.map(e=>`${e._name||'?'}: ${e.content}`).join('\n')}">${mng_log.length}</b-button>`: ''}`;

              let data = this.filterOrders[row];
              if (td.children[0]) td.children[0].onclick = _ => this.openLog(data, td.children[0].className);
              if (td.children[1]) td.children[1].onclick = _ => this.openLog(data, td.children[1].className);
              if (td.children[2]) td.children[2].onclick = _ => this.openLog(data, td.children[2].className);
            }
          }},
      ],
      stat: {
        today: {},
        type: [],
        manager: [],
        partner: []
      },
      orders: {},
      filterOrders: [],
      partnerFilter: {
        filter: '',
        shopType: 'ALL',
        filterPartner: [],
      },
      busy: {stat: false},
      modal: {ps_log: false, op_log: false, mng_log: false},
      logModal: [],
      hotSettingPartner: {
        data: [],
        colHeaders: (index) => {
          return this.partnerFields[index].name;
        },
        columns: [],
        cells: (row, col) => {
          let className = this.partnerFields[col].className;
          return className ? {className} : {};
        },
        className: "htCenter htMiddle",
        colWidths: [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 300,
        afterSelectionEnd: (row, column, row2, column2, selectionLayerLevel, a, b) => {
          this.hotTableShopsLastRange = {row, column, row2, column2};

          // 여러 row 선택이면 skip
          if (row !== row2) return;
          if (column < 6) return;

          let shop = this.$refs.hotTablePartner.hotInstance.getDataAtRow(row); // [null, shop_id, shop_name]
          const delay = {6: 'TOTAL', 7: 'DEL_DELAY', 8: 'PREPARE', 10:'ABROAD_COMPLETE', 11: 'DOMESTIC'}[column];

          this.getOrder(false, 'partner', shop[0], delay);
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
      hotSettingOrder: {
        data: [],
        colHeaders: (index) => {
          return this.orderFields[index].name;
        },
        columns: [],
        cells: (row, col) => {
          let className = this.orderFields[col].className;
          return className ? {className} : {};
        },
        className: "htCenter htMiddle",
        colWidths: [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 500,
        afterScrollVertically: () => {
          const hot = this.$refs.hotTableOrder.hotInstance;
          if (hot.getPlugin('autoRowSize').getLastVisibleRow() + 5 >= hot.countRows()) { // 마지막 4 row 가 보인다면
            // this.list(true);
          }
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
    }
  },
  async created() {
    this.hotSettingPartner.columns = this.partnerFields;
    this.hotSettingPartner.colWidths = this.partnerFields.map(e=>e.width);
    this.hotSettingOrder.columns = this.orderFields;
    this.hotSettingOrder.colWidths = this.orderFields.map(e=>e.width);

    this.getStat();
  },
  methods: {
    async getStat() {
      const j = await this.$api.getJson('/order/deliveryDelayStat');
      if (j) {
        const type = j.type;
        const manager = j.manager;
        const shops = j.shops;
        this.orders = j.stat;

        type.map(e => this.stat.type[e] = {type: e});
        manager.map(e => this.stat.manager[e] = {manager: e});
        Object.keys(shops).map(e => this.stat.partner[e] = {...shops[e]});

        Object.keys(this.orders).forEach(key => {
          this.stat.today[key] = this.orders[key].length;
          type.forEach(e => this.stat.type[e][key] = this.orders[key].filter(s => e === s.delay_type).length);
          manager.forEach(e => this.stat.manager[e][key] = this.orders[key].filter(s => e === s.op_name).length);
          Object.keys(shops).forEach(e => this.stat.partner[e][key] = this.orders[key].filter(s => +e === +s.shop_id).length);
        });

        this.stat.type = Object.values(this.stat.type);
        this.stat.manager = Object.values(this.stat.manager).sort((a, b) => {
          if (a.manager > b.manager) {
            return 1
          } else {
            return -1
          }
        });
        this.stat.partner = this.partnerFilter.filterPartner = Object.values(this.stat.partner);

        this.$refs.hotTablePartner.hotInstance.loadData(this.stat.partner);
        this.$refs.hotTableOrder.hotInstance.loadData([{boutique: '조회할 주문을 선택해주세요.'}]);
      }
    },
    async getOrder(orders, type, name, delay){
      if (!orders) {
        if (type === 'type') this.filterOrders = this.orders[delay].filter(e => e.delay_type === name);
        else if (type === 'manager') this.filterOrders = this.orders[delay].filter(e => e.op_name === name);
        else if (type === 'partner') this.filterOrders = this.orders[delay].filter(e => +e.shop_id === +name);
        else this.filterOrders = this.orders[delay];
        this.$refs.hotTableOrder.hotInstance.loadData(this.filterOrders);
      }
    },
    openLog(data, className) {
      const type = className.split(' ')[0];
      if (type) this.modal[type] = true;
      this.logModal = data[type];
    },
    filterShop() {
      const filter = this.partnerFilter.filter;
      this.partnerFilter.filterPartner = this.stat.partner.filter(e => ~(e.shop_id+ '').indexOf(filter) || ~e.boutique.indexOf(filter));
      this.$refs.hotTablePartner.hotInstance.loadData(this.partnerFilter.filterPartner);
    },
    filterShopType(type) {
      this.partnerFilter.filterPartner = this.stat.partner.filter(e => type === 'ALL' || e.shop_type === type);
      this.$refs.hotTablePartner.hotInstance.loadData(this.partnerFilter.filterPartner);
    },
    async down() {
      let items = this.filterOrders;
      if (!items.length) this.$alertTop(`다운받을 주문을 선택해주세요.`);
      let fields = Object.keys(items[0]);
      down(items, null, fields, `DeliveryDelayStat_${utils.dt()}`);
    },
    async copyOid() {
      let selected = this.filterOrders.map(e => e.oid);
      let res = this.$utils.copyToClipboard(selected.join('\n'));
      if (res) this.$alertTop(`${selected.length} 개 복사되었습니다`);
    }
  }
}
</script>
