<template>
  <div>
    <b-row>
      <b-col cols="12" lg="6">
        <b-card>
          <h3>ON GOING</h3>
          <div>전체</div>
          <h4 v-text="total.ongoing"></h4>

          <div v-for="st in ongoingStatus">
            <b-row>
              <b-col><div class="mt-2">{{st.name}}</div></b-col>
              <b-col class="text-center">
                <b-badge variant="light">total</b-badge>
                <h5><a :href="`/#/deliveryBoard?statusIndex=0&status=${st.code}`" target="_blank">{{ongoing[st.code] || 0}}</a></h5>
              </b-col>
              <b-col class="text-center" v-for="e in ongoingDayGroup[st.code]">
                <b-badge :variant="e.v">{{e.dayStr}}</b-badge>
                <h5><a :href="`/#/deliveryBoard?statusIndex=0&status=${st.code}&statusDateType=days&statusFrom=${e.s}${e.e!=null?`&statusTo=${e.e}`:''}`" target="_blank">{{e.cnt}}</a></h5>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" lg="3">
        <b-card>
          <h3>SHIPMENT DETAIL</h3>
          <div>전체</div>
          <h4 v-text="total.shipment"></h4>
          <b-row>
            <b-col cols="4" v-for="s in Object.keys(shipment)">
              <div>{{s}}</div>
              <h5><a :href="`/#/deliveryBoard?statusIndex=1&abroad_del_company=${s}&statusMulti=del_abroad&statusMulti=del_abroad_complete`" target="_blank">{{shipment[s]}}</a></h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h3>EXCEPTION</h3>
          <div>전체</div>
          <h4>{{total.exception || 0}}</h4>
          <b-row>
            <b-col cols="6">
              <div>해외출고 지연</div>
              <h5>{{exception.abroad_delay}} <i class="fa fa-question-circle-o" :title="Object.entries(exception.abroad || {}).map(([k,v])=>`${k}: ${v}`).join('\n')"></i></h5>
            </b-col>
            <b-col cols="6">
              <div>통관지연</div>
              <h5>{{exception.clearance_delay}} <i class="fa fa-question-circle-o" :title="Object.entries(exception.clearance || {}).map(([k,v])=>`${k}: ${v}`).join('\n')"></i></h5>
            </b-col>
            <b-col cols="6">
              <div>국내출고 지연</div>
              <h5>{{exception.domestic_delay}} <i class="fa fa-question-circle-o" :title="Object.entries(exception.domestic || {}).map(([k,v])=>`${k}: ${v}`).join('\n')"></i></h5>
            </b-col>
            <b-col cols="6">
              <div>배송 지연</div>
              <h5>{{exception.total_delay}} <i class="fa fa-question-circle-o" :title="Object.entries(exception.total || {}).map(([k,v])=>`${k}: ${v}`).join('\n')"></i></h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--<b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="filter">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="주문번호, 운송장 등으로 검색해주세요" v-model="form.search" @keypress.enter.prevent.stop="filter" v-focus></b-form-input>
      </b-input-group>
    </b-card>-->

    <hot-table ref="hotTable" :settings="hotSettings"></hot-table>
    <div class="clearfix">
      <b-button class="m-1" variant="success" @click="getShipment" :disabled="isBusy"><b-spinner class="mr-2" variant="light" small v-if="isBusy"></b-spinner>새로고침</b-button>
    </div>

    <c-table :table-data="items_all" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :getMoreBusy="getMoreBusy" :hasMore="hasMore" :caption="items_all.length + ' 개'"></c-table>
  </div>
</template>
<style>
@import '../../../../node_modules/handsontable/dist/handsontable.full.css';
</style>
<script>
import cTable from '@/views/TableBase.vue'
import vSelect from 'vue-select'
import {getMeta, getShopPreset, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import {ORDER_STATUS_CODE} from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import store from '@/store';
const {state:S} = store;

export default {
  name: 'DeliveryStat',
  data() {
    return {
      ongoing: {},
      ongoingStatus: ORDER_STATUS_CODE.filter(e => ~['order_receive', 'del_prepare', 'del_abroad', 'del_abroad_complete', 'del_abroad_received', 'del_domestic'].indexOf(e.code)),
      ongoingMap: {},
      ongoingDayGroup: {},
      shipment: {},
      exception: {},
      total: {},
      cateW: ['WCL', 'WBA', 'WSH', 'WAC', 'WJW', 'WXX'],
      cateM: ['MCL', 'MBA', 'MSH', 'MAC', 'MJW', 'MXX'],
      cateU: ['UCL', 'UBA', 'USH', 'UAC', 'UJW', 'UXX', 'XXX'],
      shop: [],
      brand: [],
      shopMap: {},
      shopPreset: [],
      form: {
        search: '',
        shop: [],
        category: [],
        brand: [],
        group: ['launch_date', 'discount_rate'],
        filter: ['image','stock'],
        limit: 500,
        skip: 0,
      },
      detailCond: {},
      brand_no: '',
      goods_no: '',
      items: [],
      items_all: [],
      fields: [
        {key:'html6', label:'<span class="badge badge-primary">쇼핑몰</span><br/>주문코드<br/>일련번호', sortable:true},
        {key:'_img60', label:'이미지'},
        {key:'html1', label:'주문일<br/><span class="badge badge-light">상태</span><br/>상태일자', class: 'text-center', sortable:true},
        // {key:'html2', label:'<span class="badge badge-light">해외배송사</span><br/><small>해외운송장</small>', class: 'text-center'},
        {key:'abroad_del_company', label:'해외배송사', class: 'text-center', sortable:true},
        {key:'abroad_invoice_no', label:'해외운송장', class: 'text-center', sortable:true},
        {key:'html3', label:'주문후경과<br/>현상태경과', class: 'text-center'},
        {key:'html4', label:'예상배송일<br/><span class="badge badge-light">남은영업일</span>', class: 'text-center'},
        {key:'html5', label:'상품정보'},
      ],
      perPage: 20,
      isBusy: false,
      getMoreBusy: false,
      hasMore: false,
      modal: false,
      hotSettings: {
        data: [],
        // data: Handsontable.helper.createSpreadsheetData(6, 10),
        colHeaders: '해외배송사,해외운송장,배송상태,해외배송일,예상도착일,배송경과,이슈,파트너,관련주문수'.split(','),
        columns: [
          {
            data: 'abroad_del_company',
            readOnly: true,
          },
          {
            data: 'abroad_invoice_no',
            readOnly: true,
            renderer: (...e) => e[1].innerHTML = `<a href="/#/deliveryBoard/${e[5]}" target="_blank">${e[5] || ''}</a>`
          },
          ...'order_status,del_abroad_date,expectAbroadDelDate,abroadDelDays,issue,boutique,order_cnt'.split(',').map(e=>{
            return {
              data: e,
              readOnly: true
            };
          })
        ],
        // colWidths: [300],
        rowHeaders: true,
        dropdownMenu: true,
        filters: true,
        columnSorting: true,
        height: 500,
        // rowHeaderWidth: 300,
        licenseKey: 'non-commercial-and-evaluation',
      }
      // hotSettings: {
      //   data: [],
      //   // data: Handsontable.helper.createSpreadsheetData(6, 10),
      //   colHeaders: '쇼핑몰,주문코드,일련번호,이미지,주문일,상태,상태일,해외배송사,해외운송장,주문후경과,현상태경과,예상배송일,남은영업일,파트너,브랜드,발란코드,상품명,옵션,수량'.split(','),
      //   columns: [
      //     {
      //       data: 'mall',
      //       readOnly: true,
      //     },
      //     {
      //       data: 'orderno',
      //       readOnly: true,
      //       renderer: (...e) => e[1].innerHTML = `<a href="/#/deliveryBoard/${e[5]}" target="_blank">${e[5]}</a>`
      //     },
      //     {
      //       data: 'order_detailno',
      //       readOnly: true,
      //     },
      //     {
      //       data: 'img',
      //       readOnly: true,
      //       // className: 'htCenter',
      //       renderer: function(instance, td, row, col, prop, value, cellProperties) {
      //         const escaped = Handsontable.helper.stringify(value);
      //         if (escaped.indexOf('http') === 0) {
      //           td.className = 'htCenter';
      //           if (td.firstElementChild && td.firstElementChild.tagName === 'IMG' && td.firstElementChild.src === value) return td;
      //           Handsontable.dom.empty(td);
      //           let img = document.createElement('IMG');
      //           img.src = value;
      //           img.height = 19;
      //
      //           Handsontable.dom.addEvent(img, 'mousedown', function(event) {
      //             event.preventDefault();
      //           });
      //           Handsontable.dom.addEvent(img, 'click', function(event) {
      //             utils.open(value);
      //           });
      //
      //           td.appendChild(img);
      //         } else {
      //           Handsontable.renderers.TextRenderer.apply(this, arguments);
      //         }
      //
      //         return td;
      //       }
      //     }
      //   ].concat('order_date,order_status,status_date,abroad_del_company,abroad_invoice_no,dOrderDay,dStatusDay,dday,remainDays,boutique,brand,goodsno,goodsnm,size,qty'.split(',').map(e=>{
      //     return {
      //       data: e,
      //       readOnly: true
      //     };
      //   })),
      //   // colWidths: [300],
      //   rowHeaders: true,
      //   dropdownMenu: true,
      //   filters: true,
      //   columnSorting: true,
      //   height: 500,
      //   // rowHeaderWidth: 300,
      //   licenseKey: 'non-commercial-and-evaluation',
      // }
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    let meta = await getMeta('shop,holiday');
    if (!meta) return;

    this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);
    this.shop.forEach(s=>{
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    // 공휴일 설정
    let holidays = meta.holiday.map(e=>{
      if (e.require) return momentBiz().format('YYYY-') + e.date;
      return e.date;
    });
    // 작년, 내년도 추가한다
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().add(1, 'year').format('YYYY-') + e.date));
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    window.moment = momentBiz;

    this.getStat();
    this.getShipment();

  },
  watch: {
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    async getStat() {
      let j = await getJson('/order/deliveryStat');
      if (!j) return;
      let ongoing = 0, shipment = 0, exception = 0, ongoingMap = {}; // {[order_status]:{'0':32, '1':23, ...}
      this.ongoingDayGroup = {
        'order_receive': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        'del_prepare': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        'del_abroad': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        'del_abroad_complete': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        'del_abroad_received': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        'del_domestic': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, cnt:0, v:'danger'}],
        // 'del_complete': [{s:0, e:0, cnt:0, v:''}, {s:1, e:1, cnt:0, v:''}, {s:2, e:2, cnt:0, v:'warning'}, {s:3, e:10, cnt:0, v:'danger'}],
      };
      j.ongoing.forEach(e => { // {_id:{order_status, status_date}, cnt:x} 를 영업일 경과대로 분류한다.
        let s = ongoingMap[e._id.order_status_code] = (ongoingMap[e._id.order_status_code] || {});
        let diffDays = -utils.bizDiff(e._id.status_date);
        for (let g of this.ongoingDayGroup[e._id.order_status_code]) {
          if (g.s <= diffDays && (g.e >= diffDays || g.e === undefined)) {
            g.cnt += e.cnt;
            break;
          }
        }
      });
      Object.keys(this.ongoingDayGroup).forEach(k=>{
        let e = this.ongoingDayGroup[k];
        e.forEach(g=>{
          g.dayStr = g.s === g.e ? `D+${g.s}` : (g.e ? `D+${g.s} ~ D+${g.e}` : `D+${g.s} ~ `);
          ongoing += g.cnt;
          this.ongoing[k] = (this.ongoing[k] || 0) + g.cnt;
        });
      });
      this.ongoingMap = ongoingMap;

      j.shipment.sort((a, b) => b.cnt - a.cnt).forEach(e => {
        shipment += e.cnt;
        this.shipment[e._id] = e.cnt
      });

      'abroad,clearance,domestic,total'.split(',').forEach(e=>{
        this.exception[`${e}_delay`] = 0;
        this.exception[e] = {};
      });
      j.exception.forEach(e=>{
        let shop = this.shopMap[e.shop_id];
        if (!shop) {
          shop = {boutique:'SHOP 정보 없음'};
          console.log('no shop', e.shop_id);
        }
        let dayAfterOrder = -utils.bizDiff(e.order_date);
        let delay = 0;
        // ['order_receive', 'del_prepare', 'del_abroad', 'del_abroad_complete', 'del_abroad_received', 'release_ready'];

        if (e.order_status_code === 'del_prepare') { // 해외출고 지연 or 국내출고 지연
          if (~[78,87].indexOf(e.shop_id)) { // 해외출고 지연: 예외(GUCCI UK/DOVER STREET) : 주문일D+6일째부터, “배송준비” 상태 주문건
            if (dayAfterOrder >= 6) {
              delay = this.exception.abroad_delay += 1;
              this.exception.abroad[`${e.shop_id}. ${shop.boutique}`] = (this.exception.abroad[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
            }
          } else if (e.shop_id === 79) { // 국내출고 지연: 예외1 (미스터이포터) : 주문일D+5일째부터, “배송준비” 상태 주문건
            if (dayAfterOrder >= 5) {
              delay = this.exception.domestic_delay += 1;
              this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] = (this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
            }
          } else if (~[82,89].indexOf(e.shop_id)) { // 국내출고 지연: 예외2 (원츠샵, 아만다룸) : 주문일D+7일째부터, “배송준비” 상태 주문건
            if (dayAfterOrder >= 7) {
              delay = this.exception.domestic_delay += 1;
              this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] = (this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
            }
          } else if (shop.shop_type === 'boutique' && dayAfterOrder >= 4 // 해외출고 지연: 부티크/편집샵 : 주문일D+4일째부터, “배송준비” 상태 주문건
            || shop.shop_type === 'parallel' && shop.logistics === 'balaan' && dayAfterOrder >= 2) { // 병행(BALAAN) : 주문일D+2일째부터, “배송준비” 상태 주문건
            delay = this.exception.abroad_delay += 1;
            this.exception.abroad[`${e.shop_id}. ${shop.boutique}`] = (this.exception.abroad[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
          }
        } else if (e.order_status_code === 'del_abroad') { // 통관 지연
          let dayAfterShipped = -utils.bizDiff(e.del_abroad_date);
          if (~[78,87].indexOf(e.shop_id)) { // 예외(GUCCI UK/DOVER STREET) : 해외배송일D+4일째부터, “해외배송” 상태 주문건
            if (dayAfterShipped >= 4) {
              delay = this.exception.clearance_delay += 1;
              this.exception.clearance[`${e.shop_id}. ${shop.boutique}`] = (this.exception.clearance[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
            }
          } else if (dayAfterShipped >= 3) { // 통관 지연(해외배송일 D+3일째부터, “해외배송” 상태 주문건) - shipment기준
            delay = this.exception.clearance_delay += 1;
            this.exception.clearance[`${e.shop_id}. ${shop.boutique}`] = (this.exception.clearance[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
          }
        } else if (e.order_status_code === 'del_abroad_complete' || e.order_status_code === 'del_abroad_received') { // 국내출고 지연
          let dayAfterReceived = -utils.bizDiff(e.del_abroad_complete_date || e.del_abroad_received_date);
          if ((e.del_abroad_complete_date || e.del_abroad_received_date) && dayAfterReceived >= 2) { // 국내출고 지연(해외배송완료일 D+2일째부터, “해외배송완료” 상태 주문건)
            delay = this.exception.domestic_delay += 1;
            this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] = (this.exception.domestic[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
          }
        }
        if (delay) {
          exception++;
        } else if (shop && shop.delivery_day <= dayAfterOrder) {
          this.exception.total_delay += 1;
          this.exception.total[`${e.shop_id}. ${shop.boutique}`] = (this.exception.total[`${e.shop_id}. ${shop.boutique}`] || 0) + 1;
          exception++;
        }
      });
      this.total.ongoing = ongoing;
      this.total.shipment = shipment;
      this.total.exception = exception;
      this.$forceUpdate();
    },
    async getShipment() {
      this.isBusy = true;
      let j = await getJson('/order/shipment');
      this.isBusy = false;
      if (!j) return;
      this.items_all = j.list.map(e => {
        let shop = this.shopMap[e.shop_id];
        if (!shop) {
          shop = {boutique:'SHOP 정보 없음', delivery_day:12};
          console.log('no shop', e.shop_id);
        }
        let remainDays = shop ? shop.delivery_day + utils.bizDiff(e.order_date) : 0;
        return {
          ...e,
          img: e.img_urls ? e.img_urls[0] : '',
          html6: `<a href="/#/deliveryBoard/${e.orderno}/${e.order_detailno}" target="_blank"><span class="badge badge-primary">${e.mall}</span><br/>`
            + `${e.orderno}<br/>${e.order_detailno}</a>`,
          html1: `${e.order_date}<br/><span class="badge badge-light">${e.order_status}</span><br/>${e[e.order_status_code + '_date']}`,
          html2: `<span class="badge badge-light">${e.abroad_del_company || ''}</span><br/><small>${e.abroad_invoice_no || ''}</small>`,
          html3: `D+${utils.bizDiff(e.order_date)}` + `<br/>D+${utils.bizDiff(e[e.order_status_code + '_date'])}`,
          html4: (shop ? shop.delivery_day + `<br/><span class="badge badge-${remainDays > 0 ? 'success' : 'danger'}">${remainDays}</span>` : '-'),
          html5: `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.boutique}</a> ` + (e.brand ? `<span class="badge badge-warning">${e.brand}</span>` : '')
            // + ` <a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goodsno}" target="_blank"><span class="badge badge-info">${e.goodsno}</span></a> <br/>`
            + ` <a href="/#/goods/${e.goodsno}" target="_blank"><span class="badge badge-primary">${e.goodsno}</span></a> <br/>`
            + `${e.goodsnm}<br/><span class="badge badge-secondary">${e.size}</span> ${e.qty} 개`,
          dday: shop && shop.delivery_day || '-',
          remainDays,
          status_date: e[e.order_status_code + '_date'],
          dOrderDay: -utils.bizDiff(e.order_date),
          dStatusDay: -utils.bizDiff(e[e.order_status_code + '_date']),
          expectAbroadDelDate: e.del_abroad_date ? utils.kstD(momentBiz(e.del_abroad_date).businessAdd(shop ? shop.delivery_day - 2 : 3, 'day')) : '',
          abroadDelDays: e.del_abroad_date ? -utils.bizDiff(e.del_abroad_date) : '',
          _org: e,
        }
      });
      this.items_all.sort((a, b) => a.order_date.localeCompare(b.order_date));

      // 배송사, 운송장으로 그룹화한다.
      let delMap = {};
      this.items_all.forEach(e=>{
        let del = `${e.abroad_del_company}:${e.abroad_invoice_no}`;
        if (delMap[del]) {
          delMap[del].order_cnt += 1;
        } else {
          delMap[del] = e;
          e.order_cnt = 1;
        }
      });
      this.items = Object.values(delMap);
      this.items.sort((a, b) => (a.del_abroad_date || '').localeCompare(b.del_abroad_date));

      this.$refs.hotTable.hotInstance.loadData(this.items);
      // console.log(this.$refs.hotTable, this.$refs['hotTable']);
    },
    openStatusDays(status, s, e) {
      utils.open(`/#/deliveryBoard?statusIndex=0&status=${status}&statusDateType=days&statusFrom=${s}&statusTo=${e||''}`);
    },
    filter() {
      this.items = this.items_all.filter(e=>{
        return 'orderno,order_detailno,abroad_del_company,abroad_invoice_no'.split(',').some(k=>~(e[k]||'').toLowerCase().indexOf(this.form.search.toLowerCase()));
      });
    },
  }
}
</script>
