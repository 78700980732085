<template>
  <div>
    <div class="mb-2">
      <shop-preset v-model="form.shop_id"></shop-preset>
    </div>
    <div class="mb-2">
  </div>
    <b-row class="mb-3">
      <b-col cols="6">
        <div><small class="mb-n2">담당자</small></div>
        <v-select v-model="form.manager" multiple :options="manager" :placeholder="manager ? `전체 ${manager.length} 명 담당자` : '로딩중...'"></v-select>
      </b-col>
      <b-col cols="6">
        <div><small class="mb-n2">지연주문 체크</small></div>
        <b-form-checkbox class="col-form-label" v-model="form.delay">지연된 주문이 있는 Shop만 조회합니다</b-form-checkbox>
      </b-col>
    </b-row>
    <div class="clearfix">
      <b-button class="m-1 pull-right" variant="success" @click="down()">XLSX</b-button>
      <b-button class="m-1" variant="primary" @click="list" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
    </div>

    <hr />

    <div class="mb-2">
      * 파트너 센터를 이용하는 Shop은 Shop_id를 클릭하시면 해당 샵의 파트너 센터 신규 주문/발송 처리 페이지로 이동합니다.
    </div>
    <hot-table data-key="order_stat" ref="hotTable" :settings="hotSetting"></hot-table>
  </div>
</template>

<style>
@import '~handsontable/dist/handsontable.full.css';
.handsontable .stat-primary {
  vertical-align: middle;
  color: #5b73e8;
}

.handsontable .stat-warning {
  vertical-align: middle;
  color: #f46a6a;
}

.handsontable td div {
  vertical-align: middle;
  text-align: center;
}

.handsontable td div a {
  vertical-align: middle;
  text-decoration-line: none;
}
</style>

<script>
import store from '@/store';
const {state:S, getters:{R, G}} = store;
import vSelect from 'vue-select'
import shopPreset from '@/views/ShopPreset.vue'
import cTable from '@/views/TableBase.vue'
import {commaRenderer, percentRenderer} from '@/shared/ht_helper';
import { HotTable } from '@handsontable/vue';
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp';

export default {
  name: 'OrderRefundStat',
  components: {vSelect, cTable, shopPreset, commaRenderer, percentRenderer, HotTable},
  props: ['tabIndex'],
  data() {
    return {
      fields: {
        list: [
          {data: 'shop_id', name: 'Shop ID', width: 70, className: 'truncate htCenter', renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.list[cellProperties.row];
              let id = e.users.id || '';
              // td.innerHTML = id ? `<!--<div><a href="https://localhost:3145/admin/changeUser?id=${id}">${e.shop_id}</a></div>-->` : `<div>${e.shop_id}</div>`;
              td.innerHTML = id ? `<div><a class="badge badge-success" href="https://partner.balaan.io/admin/changeUser?id=${id}" target="_blank">${e.shop_id}</a></div>` : `<div>${e.shop_id}</div>`;
            }, readOnly: true},
          {data: 'shop_name', name: 'Shop 이름', width: 160, className: 'truncate htCenter', readOnly: true},
          {data: 'shop_type', name: 'Shop 유형', width: 70, readOnly: true},
          {data: 'contact', name: '파트너 연락처', width: 120, readOnly: true},
          {data: 'order_receive', name: '신규주문', width: 70, className: 'htCenter stat-primary', readOnly: true},
          {data: 'order_receive_delay', name: '신규주문 지연', width: 100, className: 'htCenter stat-warning', readOnly: true},
          {data: 'del_prepare', name: '발주완료', width: 70, renderer: 'comma', readOnly: true},
          {data: 'del_prepare_delay', name: '발송지연', width: 75, className: 'htCenter stat-warning', renderer: commaRenderer, readOnly: true},
          {data: 'del', name: '배송중', width: 65, renderer: commaRenderer, readOnly: true},
          {data: 'del_delay', name: '배송중 문제', width: 90, className: 'htCenter stat-warning', renderer: commaRenderer, readOnly: true},
          {data: 'refund_request', name: '반품요청', width: 70, renderer: commaRenderer, readOnly: true},
          {data: 'refund_request_delay', name: '반품지연', width: 70, className: 'htCenter stat-warning', renderer: commaRenderer, readOnly: true},
          {data: 'retrieve_request', name: '반품 수거중', width: 90, renderer: commaRenderer, readOnly: true},
          {data: 'retrieve_complete', name: '반품완료', width: 70, renderer: commaRenderer, readOnly: true},
          {data: 'waiting_auto_refund', name: '자동 환불대기', width: 100, renderer: commaRenderer, readOnly: true},
          {data: 'problems', name: '총 문제 건수', width: 100, renderer: commaRenderer, readOnly: true},
        ]
      },
      S, R, G,
      form: {
        shop_id: [],
        manager: null,
        delay: false,
        list: {},
      },
      manager: [],
      shop_type: {
        'boutique': '부티크',
        'edit_shop': '편집샵',
        'parallel': '병행'
      },
      lastBody: {list: {}},
      items: {list: []},
      item: {list: {}},
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      xlsx: {
        keys: [],
        labels: [],
      },
      hotSetting: {
        data: [],
        colHeaders: (index) => {
          return this.fields.list[index].name;
        },
        columns: [],
        cells: (row, col) => {
          let className = this.fields.list[col].className;
          return className ? {className} : {};
        },
        className: "htCenter htMiddle",
        colWidths: [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 500,
        afterScrollVertically: () => {
          const hot = this.$refs.hotTable.hotInstance;
          if (hot.getPlugin('autoRowSize').getLastVisibleRow() + 5 >= hot.countRows() && !this.busy.listmore && this.hasMore.list) { // 마지막 4 row 가 보인다면
            this.list(true);
          }
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
    }
  },
  watch: {
    tabIndex(v) {
      if (v === 1) {
        setTimeout(() => this.$refs.hotTable.hotInstance.render(), 0);
      }
    },
  },
  async created() {
    this.hotSetting.columns = this.fields.list;
    this.hotSetting.colWidths = this.fields.list.map(e=>e.width);

    await this.list();
    await this.managerList();
  },
  methods: {
    async list(more) {
      const shop_id = this.form.shop_id;
      const manager = this.form.manager;

      if (!more && this.$refs.hotTable) this.$refs.hotTable.hotInstance.loadData([]);
      let j = await this.$api.postTable(this, '/order/newOrderStat', {shop_id, manager}, {more, fnAssign: this.assignTableData});
      if (!j) return;

      let list = this.form.delay ? j.list.filter(e => +e.problems > 0) : j.list;
      this.items.list = list.sort((a,b)=>(a.use_yn==='n'?10000:0) + b.problems - (b.use_yn==='n'?10000:0) - a.problems)

      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    assignTableData(e) {
      e.shop_id = `${e.shop_id}`;
      e.shop_name = e.use_yn === 'y' ? e.boutique : `[미사용] ${e.boutique}`;
      e.shop_type = this.shop_type[e.shop_type];
      e.contact = e.contact || '';

      e.order_receive = e.stat.order_receive;
      e.order_receive_delay = e.stat.order_receive_delay;
      e.del_prepare = e.stat.del_prepare;
      e.del_prepare_delay = e.stat.del_prepare_delay;
      e.del = e.stat.del;
      e.del_delay = e.stat.del_delay;
      e.refund_request = e.stat.refund_request;
      e.refund_request_delay = e.stat.refund_request_delay;
      e.retrieve_request = e.stat.retrieve_request;
      e.retrieve_complete = e.stat.retrieve_complete;
      e.waiting_auto_refund = e.stat.waiting_auto_refund;
      e.problems = e.order_receive_delay + e.del_prepare_delay + e.del_delay + e.refund_request_delay;
    },
    async managerList() {
      // const teams = ['operation', 'sales', 'abroad_sales', 'domestic_sales', 'domestic1', 'domestic2', 'sales_operator'];
      const mdOpTeams = [
        'eMEVPZ2m0D', // 글로벌커머스실
        'k1EBdZPY0y', // 로컬B2B사업실
        'bQ8XrZPjzX', // 글로벌B2B사업실
        '7907y4emEg', // 도메스틱커머스실
        'w303MBbvED', // 영업총괄팀
        'PM0v4RmeEX', // 영업전략실

        '5M0nwepNz6', // B2B그룹
        'xBEYKZbY0g', // PMO그룹
        'pVEkGny7EM', // 운영그룹
        '4bEmm97GED', // Business Operations실
        'jY0rp3JPE1', // Global Business팀
      ];
      let j = await this.$api.postJson('/user/list', {teams: mdOpTeams});
      this.manager = j.list.map(e => `${e.name}(${e.id})`);
    },
    async down() {
      let items = this.items.list;
      let fields = this.fields.list.map(e => e.data).filter(e => e !== 'problems');
      down(items, null, fields, `OrderStat_${utils.dt()}`);
    },
  }
}
</script>
