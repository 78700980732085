<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab v-if="$R('ORDER_R')" title="배송지연 대시보드">
        <DeliveryDelayStat></DeliveryDelayStat>
      </b-tab>
      <b-tab title="주문/반품 통계">
        <OrderRefundStat v-if="loaded[1] || tabIndex === 1"></OrderRefundStat>
      </b-tab>
      <!-- 2024-07-01 형준님 확인 결과 사용하지 않아서 주석처리 -->
<!--      <b-tab v-if="PRIVATE" title="배송통계(구)">
        <DeliveryStat v-if="loaded[2] || tabIndex === 2" v-bind="{tabIndex}"></DeliveryStat>
      </b-tab>-->
    </b-tabs>
  </div>
</template>

<script>

import DeliveryDelayStat from '@/views/order/stat/DeliveryDelayStat.vue'
import DeliveryStat from '@/views/order/stat/DeliveryStat.vue'
import OrderRefundStat from '@/views/order/stat/OrderRefundStat.vue'

export default {
  name: 'OrderDashboard',
  title: '주문/반품 통계',
  components: {DeliveryDelayStat, DeliveryStat, OrderRefundStat},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
